import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../components/mdx/page";
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs";
import { Aside } from "../../../components/widgets/aside";
import { Border } from "../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../components/widgets/button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{props.pageContext.frontmatter.title}</h1>
    <Breadcrumbs text="Articles" to="/articles/" mdxType="Breadcrumbs" />
    <Aside mdxType="Aside">
      <p><strong parentName="p">{`See Also`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/dashboard/indicators/#bonds"
          }}>{`U.S. Bond Market`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://fred.stlouisfed.org/series/DGS10"
          }}>{`Market Yield on 10-Year U.S. Treasuries`}</a></li>
      </ul>
      <p><strong parentName="p">{`Related Portfolios`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/portfolios/tt-buoy/"
          }}>{`TT's Buoy`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/portfolios/heine-bond-model/"
          }}>{`Heine's Bond Model`}</a></li>
      </ul>
    </Aside>
    <p>{`Bonds combine characteristics that uniquely qualify them as a portfolio component: Negative correlation to the stock market, relatively low volatility, and a positive carry. Consequently, it is no surprise that bonds are an essential part of virtually all balanced portfolios, including the `}<a parentName="p" {...{
        "href": "/portfolios/classic-60-40/"
      }}>{`ubiquitous 60/40 portfolio`}</a>{`. But in the light of historically low yields and the outlook of the `}<a parentName="p" {...{
        "href": "https://www.federalreserve.gov/"
      }}>{`Federal Reserve`}</a>{` raising yields as soon as March 2022, we have to wonder if bonds continue to play this role moving forward. Accordingly, this article takes a closer look at bonds in a rising yield environment and the implications on investment portfolios.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1168px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4a29a0f2688eb80abe0b6d586afad1c5/8cdda/10-year-yield.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "38.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABdklEQVQoz1WRXW+bMBSG8/9/1q52satpbaU0JU3AxAFjMBhjY+CZMGq1WTo6x8fvl+TTj5+/uEnN+VZyySXve79LLvcH58+Sq6gRtaGoDUL1qX9VXnVpJ9Kb4V51nMwY2M+yrEze49yU7kob5jmm2fvAsixsG2kX41HDYNmAdV3Zto2md5y0sYm0A6bJMTkHbJw/JaLqDrM4J9IXbhffu7UHd92d2NC7YDu4tJxjZHQO01v6MVA+G4x1KONQ7UCMy0FeFtZ1SaLfgrvZd8LeJQfVWcSzRdYtdgrYYWCeZwYXaI2lai26n9BmxDrP5APDMCTB7d+Eu9C8bIQ54r0neJ9A1g4pzZEqJtJOG6dAY0ba3iGemiksTCHiQzwSPlRHa3qU7pCVQsgqfUjxeFIpTdP1POsmveuup9ZtmpVuuQlJLhvKSpNLxbVUnP5kkt+Xkper5CWTfAjFe17xmon/66M4KhNcippLoRI2E4q3rODtKrg+NH8BzARjU/8SihAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "U.S. Treasury yields declining since 1984",
            "title": "U.S. Treasury yields declining since 1984",
            "src": "/static/4a29a0f2688eb80abe0b6d586afad1c5/8cdda/10-year-yield.png",
            "srcSet": ["/static/4a29a0f2688eb80abe0b6d586afad1c5/5a46d/10-year-yield.png 300w", "/static/4a29a0f2688eb80abe0b6d586afad1c5/0a47e/10-year-yield.png 600w", "/static/4a29a0f2688eb80abe0b6d586afad1c5/8cdda/10-year-yield.png 1168w"],
            "sizes": "(max-width: 1168px) 100vw, 1168px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Bond Yields Declined Since 1984`}</h2>
    <p>{`Bond yields have been declining for decades. However, it might not be obvious why this was a good thing for investors. Bonds are typically considered `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Fixed_income"
      }}>{`fixed-income investments`}</a>{`. This characterization implies that declining yields are bad because it reduces the interest paid by the bonds. However, this is only one side of the coin. `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Bond_valuation"
      }}>{`Bond valuation formulas`}</a>{` dictate that prices of `}<em parentName="p">{`existing bonds`}</em>{` must go up when the yields of `}<em parentName="p">{`newly issued bonds`}</em>{` decline. Therefore, investors reap `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Capital_gain"
      }}>{`capital gains`}</a>{` on the bonds they already hold in return for lower interest payments on the bonds they buy moving forward.`}</p>
    <p>{`To find a period of continually rising bonds yields, we must go far back. Bond yields peaked in September 1981 at about 15.8% and steadily declined since June 1984. Consequently, we need to go even further back if we want to learn something about environments of rising yields. For this article, we have chosen to go back to January 1968. That gives us 13 years of rising yields, plus another three years with a sharp decline followed by an almost equally swift recovery of bond yields.`}</p>
    <h2>{`Backfilling ETF Quotes`}</h2>
    <p>{`But going back that far is easier said than done. Back in 1968, those convenient ETFs tracking bond investments with various maturities did not exist. Moreover, even mutual funds with a similar purpose weren't available back then; `}<a parentName="p" {...{
        "href": "https://investor.vanguard.com/mutual-funds/profile/VUSTX"
      }}>{`Vanguard's VUSTX`}</a>{` has an inception date of May 1986. Consequently, we need to do more than simply backfilling the missing ETF data with mutual funds. The solution is to create a mathematical model to synthesize the bond prices from historical yield data, as those are available back into the 1960s. Then, we splice our ETF quotes with the synthetical model's data to create a single time series representing our asset.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/569c8b8b33cda12056f8acf0d450209f/21b4d/tlt-backfill.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABJUlEQVQoz41SXU/DMAzs//9tvCPxAkICjZaONWkS58PnKd5ape0EWDrFsa/nk9Numib0fY9xHDHPM0opFbKcABQCCDNL078hZ2EI7DiImS7ocs7SBOQY2GHLg6ag06vY4QNdSkmJ95Dm/BvLlByRLr040zgEHpnbWb1zlhXUyNMg5uUJ2Z6FUv6f4LrHNWd1ln5O4t6fhVMEmIWIfhfcCm05fviU8PUmVUjbzBJCOApunaARvdULeaHLt8TzSTjROrlyWkHsY/uyQCkM6wucMeDo197CXQXvr3wIZkjKLKlAPBVFve9+l816dIfOk5jZw7oAOwdYRzD2dneeMLuAEAiVXIcTBcQYFbXW5upQ91KKWmdmPfWuOWutCuWctVR7tVbx6Lsr3pBjctZiNNMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "TLT 20+ year Treasury ETF backfilled to 1968",
            "title": "TLT 20+ year Treasury ETF backfilled to 1968",
            "src": "/static/569c8b8b33cda12056f8acf0d450209f/c1b63/tlt-backfill.png",
            "srcSet": ["/static/569c8b8b33cda12056f8acf0d450209f/5a46d/tlt-backfill.png 300w", "/static/569c8b8b33cda12056f8acf0d450209f/0a47e/tlt-backfill.png 600w", "/static/569c8b8b33cda12056f8acf0d450209f/c1b63/tlt-backfill.png 1200w", "/static/569c8b8b33cda12056f8acf0d450209f/21b4d/tlt-backfill.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The chart above shows our model to backfill `}<a parentName="p" {...{
        "href": "https://www.ishares.com/us/products/239454/ishares-20-year-treasury-bond-etf"
      }}>{`iShares' TLT`}</a>{`. As you can see, the model quite accurately tracks the performance of the ETF. We are therefore confident that this method leads to valuable insights. We repeated the same process for `}<a parentName="p" {...{
        "href": "https://www.ishares.com/us/products/239453/ishares-1020-year-treasury-bond-etf"
      }}>{`TLH`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.ishares.com/us/products/239456/ishares-710-year-treasury-bond-etf"
      }}>{`IEF`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://www.ishares.com/us/products/239455/ishares-37-year-treasury-bond-etf"
      }}>{`IEI`}</a>{`, covering three to 20+ years of maturities.`}</p>
    <h2>{`Treasury Returns since 1968`}</h2>
    <p>{`We have seen above that declining yields lead to capital gains, especially with longer-term maturities. Unfortunately, the opposite is also true. For example, between 1968 and 1981, the 10-Year Treasury yield rose from 5.5% to 15.8%, and we would expect that Treasury bonds were an unfortunate investment choice in that period. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6baabdd5398e6c6f0020c350fe64fee5/21b4d/treasuries-cumulative-return.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB/klEQVQoz5VTTW/TQBD1/79zgmPhgpAoh1YoQAuoBQGlEFrUxiWtHZzGX4k/Yu/3e8huTMORkd7u253ZtzOjXS9JYkynU/i+jyiK4JyjtRYdAJAEu7ljvQF01kE0DZMs5zxJEcULzhcRhGjhSSGglIIQAsaY4Rw2AzYcMAZNVSCJI6zyORd5hKIuKNsKWrWEs72OZ4zhtgFb+YDUTcU8jRjFEeNVylI2tHD/HOngAFZVBU9rjUFnk1EfJIqUSRIyK1NWqqW7L3q4eMBgLMtyK0Pn6KSgqzKuFgGj5YK1VvcCnd9Zun52/XqDXqzreaflCSm5Xq+YrhKWTUV/MeOsWfM/7G9VfQ/z4ILZ9TlFFPA2nLLJl2zDK+b+mNnNJeObS66jG67CCatgwvLqJ2fTMxZX50yCMYvZBUQSchn/gtEa3uH4A0efX+Hg60f4QQj/+SOMdh/i2/f3ODzeQxBMcHq6h6Mvr3Hwbh+7o6c4Hn/C26MdPHjxBI9f7mPnzS6efRmxrgt4ydpi3pBnuejzPvCPeJJc9zzTd/39LVrWlowFWW/qG6eWJ3PHyZL8EVtOyoa3WQrPakmrJZzWMFIgrhWyuoFREkrevU8lOi5gtYKWEkYJhEmDom7htAKt6mOkkPC6jhprux8Cax36B9HzYc9CaQWzte4wxHV7g6/DHylfSPqt0FAtAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Cumulative returns of U.S. Treasuries since 1968",
            "title": "Cumulative returns of U.S. Treasuries since 1968",
            "src": "/static/6baabdd5398e6c6f0020c350fe64fee5/c1b63/treasuries-cumulative-return.png",
            "srcSet": ["/static/6baabdd5398e6c6f0020c350fe64fee5/5a46d/treasuries-cumulative-return.png 300w", "/static/6baabdd5398e6c6f0020c350fe64fee5/0a47e/treasuries-cumulative-return.png 600w", "/static/6baabdd5398e6c6f0020c350fe64fee5/c1b63/treasuries-cumulative-return.png 1200w", "/static/6baabdd5398e6c6f0020c350fe64fee5/21b4d/treasuries-cumulative-return.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`When comparing the cumulative returns of Treasuries of various maturities, we find that between 1968 and 1984, the 20+ Year bonds trail the returns of bonds with shorter maturities while outperforming them in later years. And over the past 54 years, all maturities ultimately led to quite similar results. However, their path has been different, and longer-term bonds showed deeper drawdowns and often slower recovery than their shorter-term cousins.`}</p>
    <p>{`It is worth noting that the higher volatility of longer-term bonds may be desirable under certain circumstances. For example, if these bonds are used in a portfolio that also invests in stocks, the two partially counteract each other because of the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Correlation"
      }}>{`negative correlation`}</a>{` between stocks and Treasury bonds, overall dampening portfolio volatility.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e22485b336429cfbc7c6553a2357b81d/21b4d/treasuries-rolling-returns.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACIElEQVQoz2VS/WsTQRS8//8PEEFEfxJBxFaxVNB+SWxtS9I2SWu8NPGS5i65mGtyucvt54zsJpQWHwy7b+bt7GP3BWk6Qa/XQxiGGI1GMMaSsJgXCkpbugDwAGOMAzbrQ661ZlVVCERVQUoJl2it4QykNehmBUtlsPbbBOlz/h9e8obO+RHrl/6kx4OozVKLRwqedOogtKWFP4WVNCxXFQK17mrdgF3LefsHLhrvuVSltxOKXAr75FZlLFtDwawwno3vJefLFQIthXOmszXe2VLdpcz2PzBOBxytJK/GKS/HCQdzzUxqSmWpQNanC/5OKypJfBtOOFnkCLQxHDRrLGcZp/2IXBTMz44pk5R3N3XWt17w9mSLN/V9ntZ22Kjtsr1IGbbPefbrEz93b3mTSRx2dplkYwRx/5rJyS6HR9scX9Q4ePuM0fEW5f2E8dFHxuEFy06X45MDxlff2dx7x70vz/lz5w2H2695uv+Kh4cfMWudUeVzBOetU15GLez1ryEMcfT1JbrhMZLZCM3bhn/fZNTBJEuQZQOIssC43cCykuhFEeKwiWa9jt6gw0U+RVBIi8qQ98I/LqNlxiSfUlnHKc/NREENMBeG7mscqy3YzwT/5HJTUzGa/0WgpKCSAlpJCCFQCQUhFLSU0EqhEgJSbOZUOa2ClsLzRSlglILc1KzKFQLn7ibdWusBWDzOHZRSfujd/qlmNpyFO2mswT/yDkJf+esBewAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Rolling returns of U.S. Treasuries since 1968",
            "title": "Rolling returns of U.S. Treasuries since 1968",
            "src": "/static/e22485b336429cfbc7c6553a2357b81d/c1b63/treasuries-rolling-returns.png",
            "srcSet": ["/static/e22485b336429cfbc7c6553a2357b81d/5a46d/treasuries-rolling-returns.png 300w", "/static/e22485b336429cfbc7c6553a2357b81d/0a47e/treasuries-rolling-returns.png 600w", "/static/e22485b336429cfbc7c6553a2357b81d/c1b63/treasuries-rolling-returns.png 1200w", "/static/e22485b336429cfbc7c6553a2357b81d/21b4d/treasuries-rolling-returns.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Graphing the 12-months rolling returns for the various bond maturities leads to a busy chart and further insights:`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`12-months rolling returns of short-term bonds rarely turn negative`}</em>{`. This statement is true for all periods, including the environment of rising yields between 1968 and 1981.`}</li>
      <li parentName="ul"><em parentName="li">{`Rolling returns of long-term bonds move more violently but typically recover within a couple of years`}</em>{`. Even in the decade leading up to the 1981 peak in interest rates, the periods of negative returns were relatively brief.`}</li>
      <li parentName="ul"><em parentName="li">{`All maturities show similar behavior`}</em>{`. For the most part, longer-term bonds just amplify the behavior of their shorter-term cousins.`}</li>
    </ul>
    <p>{`What stands out here is that rolling returns before 1981 don't appear fundamentally different from returns in later years. Instead, there is constant fluctuation with primary cycles of 6-months to 2-years length. We conclude that any trading strategy that maintains a continuous upside over its benchmark will likely do well in periods of rising rates.`}</p>
    <h2>{`Momentum-Based Trading`}</h2>
    <p>{`When thinking about trading strategies, momentum is usually the first approach that comes to mind. So why should bonds be any different? And here comes the spoiler: in our testing of dozens of configurations, momentum-based trading does not provide any measurable upside. But let's take a closer look.`}</p>
    <h3>{`Allocation by Percentage of Leading Periods`}</h3>
    <p>{`We define a simple momentum strategy as follows:`}</p>
    <ul>
      <li parentName="ul">{`trade SHY, IEI, IEF, TLH, TLT`}</li>
      <li parentName="ul">{`rebalance weekly`}</li>
      <li parentName="ul">{`measure momentum over 1, 3, 6, 9, and 12 months`}</li>
      <li parentName="ul">{`for each momentum period, assign 20% of the total capital to the asset with the highest momentum`}</li>
    </ul>
    <p>{`This scheme slightly expands the concept of assigning 100% capital to the 'best' asset.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1966fbc061a72b42f5946fc10278be5f/21b4d/mom-lead-periods-exposure.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAC7UlEQVQoz4WO309bBQCF75/iP7AYE5O9+WB8VBPjiy9OX1zcxjbmjAIljMtdC93oIF0olMJYxYK5t00L9EKR3nJtaaG05YdQYMAKLIHqmK2urLel9RxDfNcvOW9fTj7h+fIznPkyqGcKrM6/QH3bYDWRo5H8g7VkHuXAKk9nf2U1necbXxqV8D5rq3+yEsvxPHXM6tIrVFcKfL1egFGuQCiljlFxzaEey7MyuQVkq3wT3uBJaA/G/AEL/dPY/imG37QdlJw/81UgyaJ+xNJEioXIDvOhQ/yVOOHLxROcV88hlJaOaHQ5WQ7v41TRWds2mFej1Adk5oJZbt73MDIS5C/eBZ4+8DDikxn16dxz+xgJZPijJ4MtbYWZ0CpYA4Ri4jmK5m4U/as8eDqBevaMu6pO2T7KTSXFpRYHZdcMfZ44c5ZB+kefcMatcd35lP7xGB8700gFk9Qm1sD6xWFyn7nOZr7oDzLdN0Cs/E5djVGyjXBxeJbqjVZ29WkUh5a40Galx9pBT7dG3Wzm8GCA0v04VPciXOP7rJdeQ3gZ32XGbOLeI4VzfS7WEoec8cfY8NDLoHOW8p0mttvD/MaxzGCrmb1mia7Oaaqm7/jIPs4b0gKGhxJ87N4hjTKEI22LaquJOx0ujvSO8Wwuy7HRKD+wJOjoDdPZ3Mg7Vo1XbBkO3W3izXtWmkWZP1z7nN92OHi5LY6bPVGKjjWwWoFwEFrjWHM75iUHrN1+FKfTeDI4iffFJNqlEMSme/i6O4xPzQnYWmz4wmTFdTGAnu+/QoNlAJdb4rjyQOct+wb+NgwIm7IOqaGFkqmHn4mzPBz20tLZy0u3l/nJNYUf3bLxQynES7dnePWum+80dPHdxhC/bLvKj0UH37oe49uNc3xPzKJSLkM4zGxzUglAnQxjZGoNx5EFBEMRdCp7cCkr6Jc1OKbWYVE2MOaNo8sbwUPvBtxTCgaCUXTIu7Aqm7CrR6hcFPJf8N8D/t8hLvgH/cu5QWZ8AJAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Momentum Strategy for Bonds",
            "title": "Momentum Strategy for Bonds",
            "src": "/static/1966fbc061a72b42f5946fc10278be5f/c1b63/mom-lead-periods-exposure.png",
            "srcSet": ["/static/1966fbc061a72b42f5946fc10278be5f/5a46d/mom-lead-periods-exposure.png 300w", "/static/1966fbc061a72b42f5946fc10278be5f/0a47e/mom-lead-periods-exposure.png 600w", "/static/1966fbc061a72b42f5946fc10278be5f/c1b63/mom-lead-periods-exposure.png 1200w", "/static/1966fbc061a72b42f5946fc10278be5f/21b4d/mom-lead-periods-exposure.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`As anticipated, we see the strategy rotating nicely into shorter-term maturities between 1978 and 1981. This should help reduce interest rate risk. In other periods, e.g., from 1984 to 1987, we see the strategy rotating into long-term maturities. Doing so should help reap the profits from declining rates.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/eae275fe683b889085d35fb7155c46be/21b4d/mom-lead-periods-rolling.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB20lEQVQoz21Ty4oTQRTt//MTXIg7P0Jw48LF6FLBAdHZiPgDsxgQsjJgCLgRYTRPk7FN0q/qetc5UlXJTJQcOBT31ffZRbmrsF7foCxLCCHQdh0Ii+kfS6EDSRDIDHt67yPhvKfzHlG21kIphcJ7x2M4gAvR8f3XFXa9Q/riaR5wK9d1jcIYi1UnYYPHRmooG/D0aop7r79xvGowWm/57ssNBz8rzpueldZslaP0huOl4GRjsBEWw3lD0VQofrc9nw9mfDta8ezTkufjH3z44TsfXEyS/v7FNR99vObjywmfXE75cjjjm9GCrz4veDaY8cVgjvPhLzy7mlP2HYpOaXogZY1orCIZjrohbfBpdnGOxoFgYNla1tJx21ssa8NWWUhtWHh3N8MYdMBOeJatS7q4nE3n2arAE8hBAIzWKIy1/+hjcKs8pQmJjfSsek9lsxztxuXt5wJwAJumQbHrNDsdYD0QArDpPLbCQ+iQvI0DAu4iqz7bu729NyEtMnW126FwziImrGVuyXr818tpRP+yzdU3MnBdW0op49kYGqNhrcHh1VonxpOKbzzYLEdb9uuVxmrbQ2mDXmo0QiIWV8Rs8epDCLf0+fpTi/GNf8GxT7YFMA0DSYe97S/KQEwj7ipOEQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Momentum Strategy for Bonds",
            "title": "Momentum Strategy for Bonds",
            "src": "/static/eae275fe683b889085d35fb7155c46be/c1b63/mom-lead-periods-rolling.png",
            "srcSet": ["/static/eae275fe683b889085d35fb7155c46be/5a46d/mom-lead-periods-rolling.png 300w", "/static/eae275fe683b889085d35fb7155c46be/0a47e/mom-lead-periods-rolling.png 600w", "/static/eae275fe683b889085d35fb7155c46be/c1b63/mom-lead-periods-rolling.png 1200w", "/static/eae275fe683b889085d35fb7155c46be/21b4d/mom-lead-periods-rolling.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`But, unfortunately, that's not enough. Indeed, the rolling returns show how the strategy cuts risk and improves returns in the periods mentioned above. But in many other periods, e.g., from 1987 to 1992, from 1999 to 2004, and from 2006 to 2011, the strategy trails its benchmark.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/df3df94a601d657e4fc243a1aa6ea4a1/21b4d/mom-lead-cumulative.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACdElEQVQoz21TTUtUURg+VNYmCqIvq0V/oEXQpl2L8ge0kQIJcjIXko2CCe60VWmiaZCUbYoWFRG0KBE1dTSKQuez+bijk5gzYzofzcw9d+49zxP3XiQJX3h4n/dwnue8L7xHZDdzSKfTyGazKJfLKJVKMAwDSilalgXTUlQK/BeAbkqG0gW+Xsiw420K9U80XuyPYqNQhrAskzuFgh2ug41Ipoyh6QzqR5M80xPFQW+IojFE0RCEuBrgMW8UhbIBUa1WHcH2MC1stYRpLc/LIxoP3A5QNCzaBhTXghSNYe5qCnNvcxjCE8LpzijTv/OOIW25peCMBuU6vQ9v8HzvD+5uDlI0BCg8tjjCmuawgz03w9zd5GSI67ZhjBnXcPvIoEWDj+d/cV9LgOKKn8ITorgRovDYXe0ATxB2x7XtERRKEkJZJldyBn3JIjverPHSQIJHvDEeatF4ql3j0dYEj3sTrG1z84k2jbVeGy4/2a7hcGuc57qXUCxLiMnlVT5dWObdiSRHv63w4fQa+z/HObIQ5/CXJEcXlzjgS7FvNsnBuRTvzWjsm4+xf26Jvb4E788k0ftphUO+n6hUTAjf6gpfJRLomYmgyCLu+OYxGP6K8UwcH9Zi8G9m0DXlR+fkIm6NfUfdi1k8j8RR93IK+7vHcHbYh5qucVx4NstMLgdBYvuSMVhJUVL+v0JO1g0ynXN517s0H3zcZL5EPprIU1uXLBZzELqU1KV0lrkidaiqBSklKroOaRjQpQ6pS+i6DrNqOFCWhD/1B+v5CkgTRBWGlI5O2K/ZP0Ip5cCyYVnYOrOzUa3CtM+cWjncXV2bu/XW/b8XfNw4cvwTGQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Momentum Strategy for Bonds",
            "title": "Momentum Strategy for Bonds",
            "src": "/static/df3df94a601d657e4fc243a1aa6ea4a1/c1b63/mom-lead-cumulative.png",
            "srcSet": ["/static/df3df94a601d657e4fc243a1aa6ea4a1/5a46d/mom-lead-cumulative.png 300w", "/static/df3df94a601d657e4fc243a1aa6ea4a1/0a47e/mom-lead-cumulative.png 600w", "/static/df3df94a601d657e4fc243a1aa6ea4a1/c1b63/mom-lead-cumulative.png 1200w", "/static/df3df94a601d657e4fc243a1aa6ea4a1/21b4d/mom-lead-cumulative.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`And when we look at the equity curve, it seems almost as if nothing has changed. Granted, there are some periods where the strategy reduced drawdowns. However, these are nullified by other periods, especially in the last ten years, where our trading resulted in almost twice the benchmark's downside. So, all in all, it's pretty much a wash.`}</p>
    <h3>{`Allocation by Percentage of Positive Periods`}</h3>
    <p>{`We define another simple momentum strategy as follows:`}</p>
    <ul>
      <li parentName="ul">{`trade IEI, IEF, TLH, TLT, and use SHY as a safe instrument`}</li>
      <li parentName="ul">{`rebalance weekly`}</li>
      <li parentName="ul">{`measure momentum over 1, 3, 6, 9, and 12 months`}</li>
      <li parentName="ul">{`for each asset and momentum period, assign 5% of the total capital unless the return is negative`}</li>
      <li parentName="ul">{`allocate any remaining capital to the safe asset`}</li>
    </ul>
    <p>{`This method aims to keep a mix of maturities. This less aggressive stance may help prevent the strategy from ending up on the wrong side while holding an aggressive position.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/831dd0afc92b519c3c870e69390d8a6d/21b4d/mom-pos-exposure.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAC+UlEQVQoz4WLW0yTZwCG/5tl1ybeeLUYt6t5oxcaLxYXp4nxNAVdNMuYk40ORYlUKwRpMS3loCsMOmMBEctfWpCDtClUwSFBkbNolNDIQaJZsmWGg+33/f/f9n0X4v18kjfPzfsoC0MvIZ/8i8TIO+oP3yD5XFC/N0ut6wX1vgVorePUO57RePCa+oPXMB7/zeS0wTUnxpeYmFxF8mmM+uQ7CKlBiQ8vQlfHYXROU17/ExhZoXZriLHLTdAan/B9QSPiV0MQVT0Q10I0Wp8xNfgPdd8EE5EFJvveINX3lvr9WWiJJBTt0QxFRQc1dQCruU4a4ShFlZ8r2VbGf2vk8k85FC4PVy0uvr9cQ+FqofT0M16mrjUU7jB0/xhlxxi0FKHI3scQjnLoDbcp8jOh3xmmVm6lzD9MzX6RMncXEzUXKZ15lCV5FFcKGbNVUV4rp3bDy7izEro3TNkahAZCifV2M+HIoPDYKat307gT5IrrF8YrtnHF9T2XnV9wqeZHapVHKN3pFI50GqUmioqTFO4rTDZcgKHWQW9v4FIsBeWv0QinqtM41VzAnrqdXPbVcyCQxYB/D3sajrI6sI0tXSZ21x9kd+A4h27u5YL3BEdvpHGm/gKnvNl421zGxV4PNUEokxMR2u6m0evL4omOQ5zyuegM/sy0/mO0dabzm77dtAycZk74ELMfZtIc2k/7/VMs9e1hUyCTJS0ZCLYUsm3ADcYIJRJqZ4YnCw73WXztP4+7PitO1RZhh+rAt14LNtbZsa/tJr5qK8DezqvY2X4GB4PFOHArA2b1DE52XYKzq4iunlowLqH4a73Y/Ovv/M5m5fqcTp6z27j9XCM3mO7xM3M7PzE94pb8Ea7L7eaXeYP81NzGzy1hrreo3Fp6m5uKw9zlbOIP1RGk9FUog0NjtKrDcIX6YfZG8UdgENbmMRSoL1Doj+Js8xzK/LPIV6Mo8U/DpC7ikvoKeb4oilrnUByYh903g8rQPKSUUPgB/P+S+PiHWOM/SLGnI+myL1AAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Momentum Strategy for Bonds",
            "title": "Momentum Strategy for Bonds",
            "src": "/static/831dd0afc92b519c3c870e69390d8a6d/c1b63/mom-pos-exposure.png",
            "srcSet": ["/static/831dd0afc92b519c3c870e69390d8a6d/5a46d/mom-pos-exposure.png 300w", "/static/831dd0afc92b519c3c870e69390d8a6d/0a47e/mom-pos-exposure.png 600w", "/static/831dd0afc92b519c3c870e69390d8a6d/c1b63/mom-pos-exposure.png 1200w", "/static/831dd0afc92b519c3c870e69390d8a6d/21b4d/mom-pos-exposure.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The exposure chart shows how the positions in the main assets, TLT, TLH, IEF, and IEI, are scaled back in periods of negative returns. We can see this nicely for the time leading up to the 1981 peak in interest rates. However, most of the time, we keep a blend of maturities. What sticks out is that there are only very brief periods during which all four assets are fully invested. Most of the time, the strategy keeps at least 20% in T-Bills.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f7728cc61036030e2a62e4014b6659cc/21b4d/mom-pos-rolling.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACAklEQVQoz3VSPW8TQRC9v8SPoEDiB1BRUlHQ0BAKOvgBiALRRCICCUQRkEBQIagoEBBCsOMkxI6dcPbZd7e337vvoT37IInESE9vZvbt7MdMls8XOD4+QZ7nkFJiUdfYKwoM5jWl8wRIAC3iCiEE+hDgQ0g+UmydgzEGWQiep81H8uXPnHffD1DrAJIg44rPoLO/cVmWyKx12JlVUN5jVEuECKy93sfF+z1+PCywsTXk+ucxPxzOuJWXnDQNwcipVhwLmWrBhIDv0wWbqkSW14prb/b48NMRb27+4pOdA15+tM1LD/q8sbnLC/e2eXVjl9dfDHhlvc9bb/t89mPEa897vPNun0+/TfD4yxi3Xw1pjURWNIpTaXnSaNY68utsyrFQlDYwbyytI4eVZC4cfwvb8qQ2HC0Mh6VmbyowKBQPigZCaWTx3B925gJYqtj6xqE9TOhIH3BeukoA1qameL8Kl/mFCpwJz1IFNiZyIQMrFdpCSZPytQ7tgV3BZDGCZVUhq6SldoAPgPERU+Hb7qa4E//r5NJPupnwqFSAMLFtZKo8n8+R1dJCGFCYSOXOPgngfy2tFU1ob5z2Ch3YSIXMO0tnLZyz6DgNaEIaqcRaaxidYtsi5VqttYjBoZYGR7MGxlpk6bQ07TGmq8clL6e/fW5i5xw6Tbd+mhOImH4SfwATJkeQyXA0vwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Momentum Strategy for Bonds",
            "title": "Momentum Strategy for Bonds",
            "src": "/static/f7728cc61036030e2a62e4014b6659cc/c1b63/mom-pos-rolling.png",
            "srcSet": ["/static/f7728cc61036030e2a62e4014b6659cc/5a46d/mom-pos-rolling.png 300w", "/static/f7728cc61036030e2a62e4014b6659cc/0a47e/mom-pos-rolling.png 600w", "/static/f7728cc61036030e2a62e4014b6659cc/c1b63/mom-pos-rolling.png 1200w", "/static/f7728cc61036030e2a62e4014b6659cc/21b4d/mom-pos-rolling.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Much like before, the rolling returns show this strategy deviating only very little from its benchmark in either direction. Also, we can see the strategy consistently underperforming its benchmark since 2000, even though by only a small percentage. With these rolling returns, it is no surprise that the strategy's equity curve looks almost identical to the benchmark.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6b8592958ead94582e03e78a4636ed61/21b4d/mom-pos-cumulative.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACfklEQVQoz21TS09TURA+grg0EYOiK3auXRI3Rn+AiUsiiloBNUo1JrhRXGAkQrRGF7IhIbh1YSSRIIqg5SXyKLSlpQ8EIqWFllIu99zX95l7LyALJ5nzTebxnZmTOSKdzSGVSiGdTkNRFBQKBUgpYVkWTdOEYVo0LfCfWNhQVfoTWT7rXeKVzjjO+yKsbAkjm1cgTNPg/8QCsMNjn4hmtuH7lsK5V1GeapqDqAtQVM9QXJ6FqJph+f0INhUNQtd1p2BXbCLDxG5L6J3L8UJ7jIe9MxTV0zYBRU2QRTdCLKkL8VB9CMITREVjhKm1DYeQdrk9lmW3tEP1KbzOs74Ii+pm3E48dnGYJfUhHqwLsbg2xKJax4a4FkTFwyhXXcL9I4OSkr6BZRbfDFBUBSg8QR6oDVJ4Zl29vg9dG6JmlicfhJHfkhCEweS6ZPdsjtUdSzzTNs/ShiiP3I7zxL04j96JsaxhnmUNLh73xnisIe5gudfOiaH0bpSnnySwqUiIH4t/2P4ryce9cb4dT7L16zJbh+f4eiLMFyPzbJ+K0jeU5MuhBH3+BbYMxtgyHObz73E2D0T5tD+B5s+LfONfhKIYEB+Sc3yXCKAjEsCqlcHV7lE0jg3g/cokuhYmMZb9jaahCbRN/8SjkVFU9XxBZ3waFz/24VJPP7yDflR29uFW3xBXsnl7ZHP/krFraZzxwvrOi5oO5s1tBw3q3NJc31Qmw4IhHXtD05k1CszkMhCqlFSlxK6uqmvIq/Zya5Cahm2pQrNjqoSu6TB0DduqClM3oNk5Uoep69ClBkM3IOwb7B9hWRZMywRB17Z9gIOarsPYy3Fj5l6O5cR2Of4CcafcpP2GP/UAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Momentum Strategy for Bonds",
            "title": "Momentum Strategy for Bonds",
            "src": "/static/6b8592958ead94582e03e78a4636ed61/c1b63/mom-pos-cumulative.png",
            "srcSet": ["/static/6b8592958ead94582e03e78a4636ed61/5a46d/mom-pos-cumulative.png 300w", "/static/6b8592958ead94582e03e78a4636ed61/0a47e/mom-pos-cumulative.png 600w", "/static/6b8592958ead94582e03e78a4636ed61/c1b63/mom-pos-cumulative.png 1200w", "/static/6b8592958ead94582e03e78a4636ed61/21b4d/mom-pos-cumulative.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`We tried improving the strategies in many ways. We experimented with more luxurious methods of measuring momentum. We tried different longer and shorter periods to measure momentum. We substituted the assets with others. And while the graphs changed some, none of the many variants we tested resulted in a continuous upside over the benchmark or improved risk-adjusted returns. This effort ultimately leaves us with only one conclusion: momentum doesn't work well for trading bonds. And while this is a valuable finding, we still need to understand why because this insight can help us identify better strategies.`}</p>
    <p>{`Momentum strategies typically measure one asset's momentum relative to the others in the universe. In doing so, we eliminate so-called common-mode noise, which should improve the ranking quality. This method works very well for stocks because most have a beta to the overall market not too far from 1. However, this is not the case for bonds: their beta to the overall bond market easily ranges from 0.5 to 2.3. Under these conditions, there is very little common-mode noise to eliminate.`}</p>
    <p>{`Above we found that bonds are highly correlated and mainly differ in their beta. It is almost like picking from a set of assets that only differ in their leverage. In upswings, long-term treasuries most often lead the universe, only to be the laggers in downswings. This results in leaders and laggers toggling their positions in rapid progression. The resulting noisy momentum signals are not suitable to reliably rank the assets. At the same time, more aggressive filtering makes the strategy too slow to respond to the markets, ending up on the wrong side of the market for too long to create an edge.`}</p>
    <p>{`While we should not completely give up on the idea of trading bond momentum, any successful algorithm needs to take these unique properties of bonds into account.`}</p>
    <h2>{`Heine's Bond Trading Model`}</h2>
    <p>{`One way to improve a trading strategy's quality is to include macroeconomic indicators in the decision-making process. We have seen this approach in action in strategies trading the stock market, e.g., `}<a parentName="p" {...{
        "href": "/portfolios/tt-market-vane/"
      }}>{`TuringTrader's Market Vane strategy`}</a>{` or `}<a parentName="p" {...{
        "href": "/portfolios/keller-lethargic-asset-allocation/"
      }}>{`Keller's Lethargic Asset Allocation`}</a>{`. However, we need to go far back to find precedent in bond trading strategies: `}<a parentName="p" {...{
        "href": "/portfolios/heine-bond-model/"
      }}>{`Heine's Bond Trading Model`}</a>{` was first published in Nelson Freeburg's `}<em parentName="p">{`Formula Research`}</em>{` newsletter in 1995. Mark Boucher picked it up in 1999 for his book `}<a parentName="p" {...{
        "href": "@book-boucher-hedge-fund-edge"
      }}>{`The Hedge Fund Edge`}</a>{`.`}</p>
    <p>{`The rules to Heine's model are surprisingly simple:`}</p>
    <ul>
      <li parentName="ul">{`If bond prices are trending up, score +1 point; otherwise, give 0 points.`}</li>
      <li parentName="ul">{`If long-term treasury yields trend down, score +1 point; otherwise, give 0 points.`}</li>
      <li parentName="ul">{`If short-term treasury yields trend down, score +1 point; otherwise, give 0 points.`}</li>
      <li parentName="ul">{`If the Dow Jones Utility Average is trending up, score +1 point; otherwise, give 0 points.`}</li>
      <li parentName="ul">{`If the CRB Index is trending down, score +1 point; otherwise, give 0 points.`}</li>
      <li parentName="ul">{`Rebalance weekly. If the total score is three or more, hold bonds; otherwise, carry cash.`}</li>
    </ul>
    <p>{`In a nutshell, this strategy follows two investing mantras: (a) Don't fight the tape, and (b) don't fight the Fed. But what are the last two rules for? The `}<a parentName="p" {...{
        "href": "https://www.spglobal.com/spdji/en/indices/equity/dow-jones-utility-average/#overview"
      }}>{`Dow Jones Utility Average`}</a>{` represents a heavily leveraged industry, and serves as a proxy for the overall health of the bond market. The `}<a parentName="p" {...{
        "href": "https://www.refinitiv.com/en/financial-data/indices/commodity-indices"
      }}>{`CRB Index`}</a>{` represents the commodity market and serves as a proxy for inflation.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a5d000924ae577cefda813fb602ddd11/21b4d/heine-agg-cumulative.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACU0lEQVQoz11STU8TURR9ApKYAImJO12wY6Fb927c+B8MgpS26MYEgnFhoolxZyKJ3y6MGGGjJsalG4lBCSltYWZKOy32w6+WUqel0/nom3PMTBEqJzm559777sl7eVdUagYqlUrApmlCSkkpJdpSwnElAXAfALO/Lazkmny6tM2J+TwvzGVx5naKp28pMJo2hJRtdiGYlt5/Ob7kWph58wtn7+o4Ma1QRBIQY3GKS0mfEBfXeXJ6E3Xf0HXdYMiHB9CVnRtJCb5PNHj+fs43oBiNU4wlKSYU9kxqPBrW2BdOsT+SgrisYng2w3LVCAzpgWx7B0/bqrg8dy9LMb4WmPgG/RHfJMXeSS1gT0CVfWENYlzF8PU9Q9k+eLLpSr5Y2ebQtSTFaIK94c6wCKkUIaXDCeVAh1QemVQhxhSemtlEw3QompbL5ZzJ2bc/OXIzTRFaZ394kwNRnccimT2mOTilB7XjV7McmtI5GM1yIJrh0JUM+kJpjtzIoWHaEEq1zAW1yOexAt9pZX5Qa3yl5Lmg5bmoFrmoFfh6o8RHsRyfxLZ4ZynNudUMH6yl+Sz2jY9X83iZLHI+UUCz5UCUzCrLcgcG/wC0sdRQEG/pyLd/QHdL2PZqiJs5LBs6vtazeKjF8amq42NNwcZuCWtGEapVwOeazp16HQLdv0EybX1nG17XEpFtSB6GhHcolzQMA8J2bFq2Ddtx4EfHcWDbNizbCnTQs2y0LCvQlu3AtFrYtUx0z/l9/7zo7JyE53n79POgho72d7X7jK/xL/r7u1f38RftzN+uOSQfYAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Heine's Bond Model (AGG): Cumulative Returns",
            "title": "Heine's Bond Model (AGG): Cumulative Returns",
            "src": "/static/a5d000924ae577cefda813fb602ddd11/c1b63/heine-agg-cumulative.png",
            "srcSet": ["/static/a5d000924ae577cefda813fb602ddd11/5a46d/heine-agg-cumulative.png 300w", "/static/a5d000924ae577cefda813fb602ddd11/0a47e/heine-agg-cumulative.png 600w", "/static/a5d000924ae577cefda813fb602ddd11/c1b63/heine-agg-cumulative.png 1200w", "/static/a5d000924ae577cefda813fb602ddd11/21b4d/heine-agg-cumulative.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The chart above shows the strategy's performance for the aggregate bond market. Out of the box, this marks a success. The strategy has managed to create an upside over the past 54 years. But even better, the strategy has also dramatically limited the downside. Most notably, Heine's method worked very well in the time of rapidly rising rates before 1981, but the strategy's positive effect continues throughout the full simulation range.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/293f0a9b56edce73b7d7d246a7e4c5c0/21b4d/heine-tlt-cumulative.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACh0lEQVQoz2VTTU9TQRSdhchel0aXxhAi7NW40J0/wIWa8BEVdYGJkhgR0T2JxI+NAYSVihIJC1nUqEHECEKh9AOEtrT2u4UW2r735r1555j3UES9yc25c2fOmXsnd0Ruo4RcLodCoYBKpQKllOP8jbYNWja4y7YXsDkfr7DHk8LZvjCP9fixsVWFUMr6/zBJR8MG8CuHWMHEy5lNPPqYY8ebBE4+DLGmfY6i2QtxwctDt0LYqhoQpmm6BLhkOCK01J+KVrOS7cNJ7r/po2ieoWieo2jyUjT5KC4GWdsWgmgJ4nDnCvMbm66gW4IjpHa1NrZQ4qneVe67sUjRMktxycfaKyHubfvjey4HWdMWhGgNuILZQhFCWX+1zGTR5NXnPyjaZinOfduupCVA0eynaFr8x/1OHuL8Ig90LKGsSQpNGixokp7lIrvGMjz9eIkHO/2s61zj0a4Y6+/EeKQzwvquKBu6o2zsXmPD3RgbHLwXZeP9KOq6wjzTG0dFMyC8qTxHgimOB0ocCSU4FszzlS/L0UiEb8NJjkUj/BDP8HUowaG5BPvm1jiwuMKh+TifzaQ4OJNG/1Sewwsp6LoF4Ul+5+CKH58ycfg2k3iXDuNBYBqjWS9G0l5MF2P4XFxBf3gWt798xYkX43i6Oo3j/ZNoHBjH9ckJtHomcO39JDP5CkRRlrDJLcattPuGU9UA4yrlxhp0F4t2maRilRVqynRzT7zL9KTDLHOdCaRcfmo9A2FJi6ZhwpIWDEMiXV1HUStDGhKGlNB0HbpuuC4NE6Y0IXUTSS2Lkr4F01BQ0oam6ZBSQji3Ob/Ctm0oW4Eg4MRKubPpoDOr1s6Z7T3ahK3sHZ6Djv0EQVzmRDsnsd4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Heine's Bond Model (TLT): Cumulative Returns",
            "title": "Heine's Bond Model (TLT): Cumulative Returns",
            "src": "/static/293f0a9b56edce73b7d7d246a7e4c5c0/c1b63/heine-tlt-cumulative.png",
            "srcSet": ["/static/293f0a9b56edce73b7d7d246a7e4c5c0/5a46d/heine-tlt-cumulative.png 300w", "/static/293f0a9b56edce73b7d7d246a7e4c5c0/0a47e/heine-tlt-cumulative.png 600w", "/static/293f0a9b56edce73b7d7d246a7e4c5c0/c1b63/heine-tlt-cumulative.png 1200w", "/static/293f0a9b56edce73b7d7d246a7e4c5c0/21b4d/heine-tlt-cumulative.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6e1647a67f9ea53f7bd6b51385ff955b/21b4d/heine-lqd-cumulative.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACZ0lEQVQoz21SzWvUcBD90S9BEdQWTx566cGreNGbV/8IbW1Nu9a2eCstHsSDIl48FS+CVcGPgwdBih8oFUGLdLfdTdbdbbqx3Va7m2Y32SabZJP3JGnphzjweDPvNzP8hhlRNmrYKJeh6zosy4rh+00EQUDPDwiA+81yfOR/Nzin2nz4Waf0uITzd1SeuZ2FYdoQQdDkP4Yg3PMjfFMdTL4u4+KDFZy+ucQTN7IQQ4sUfUmKK0mIS/PsnpBh2S6E7/txUWRhCPrB9o/8JvhizuSF+xqFlKa4nKLoTVIMJCmkBbZcS7F9SGbHkALRL6NnosCKYcYNGYIMwr3R5DWX5+6qFL0piv4kWxML7BhOsz0hs31QYZuksFXKskVS2DaoQFyR0TNZ4IZehQj3jbxq+Lw3o/PQcIaib56tiUW2SDLFgEzRv8MDmYO4KkP0Ztg9/hN1x6MwHZczisnBpyWeGlcopCQPj6R5fCTPo8MRcjx2Pc/O0QI7R5d4cmyJXTGrcdw1VsCRRJ5nb2lRQ4iiUeN7tcK3uTJniwa/a3V+1ar8sLzBj2qFn4oVvsmv81n6F6cXNU79UPkoVeTUfIFPMkU+T6/hlVLiS0WD7XoQulujSxtNOiB9aP46DFRRp4VKuAmXDnLuKuStEuT6Gt79KWBWX8b08gJku4SkuYKsU8IXI8eqZUI0o3XGa94+Es0t0wk87mr/sUjedJ0DmocmLdOE8DyPruvC8zxE7Hs+PNdDY0eL2Gk0YkR+BLvhwGpsxb7reXDcBmp2Pa4TUfcgCBCG4S6iONaAmKNb3Z8T+dHjds5eXZT/Fz755J2/dCSKAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Heine's Bond Model (LQD): Cumulative Returns",
            "title": "Heine's Bond Model (LQD): Cumulative Returns",
            "src": "/static/6e1647a67f9ea53f7bd6b51385ff955b/c1b63/heine-lqd-cumulative.png",
            "srcSet": ["/static/6e1647a67f9ea53f7bd6b51385ff955b/5a46d/heine-lqd-cumulative.png 300w", "/static/6e1647a67f9ea53f7bd6b51385ff955b/0a47e/heine-lqd-cumulative.png 600w", "/static/6e1647a67f9ea53f7bd6b51385ff955b/c1b63/heine-lqd-cumulative.png 1200w", "/static/6e1647a67f9ea53f7bd6b51385ff955b/21b4d/heine-lqd-cumulative.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Heine suggested applying the strategy to other bond markets, and so did we. We tried U.S. Treasuries with long maturities and investment-grade corporate bonds. Again, Heine's Bond Trading Model demonstrates a substantial reduction of `}<a parentName="p" {...{
        "href": "https://www.finra.org/investors/learn-to-invest/types-investments/bonds/understanding-bond-risk"
      }}>{`bond risks`}</a>{`. `}</p>
    <h2>{`Expanding Heine's Model to Multiple Markets`}</h2>
    <p>{`While `}<em parentName="p">{`Heine's Bond Trading Model`}</em>{` is a great start, it is limited to trading a single bond ETF. However, to create a solid upside in a wide range of economic situations, we want to expand the strategy to cover multiple bond markets.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e910fda2f2987aaebe48a3f10860d1e8/21b4d/heine-lazy-meta-cumulative.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACZklEQVQoz22Ty08TcRDHf6IQT9w0Gh5yM/HizZv/h4mJCCg0BvFRQG8cjCcPHkiMBg2JiU3DBS5qiGI4aISI0sp2W2lLoSzQB2nL0n10H9+v2W0hYpjkm/lO8pnJHGZEoVRBoVhELpfD7u4uqtUqLMuC64K2Ax4XpuXyS1zF+OciB0MKrj5L8tJYDGVVh3Ac+xD02l3U1ShRNV1IWyZmZRUTX4sYmlJw+WkCIhCluLkM0RuBuBFh22gMe1UTwtsGIBwXOBjiaTmrczCs4OJYgqeHohQ9yxTdUYruCEXPbzb1y2wOyGwJyBB9MroerTK/W4GoWdbhhttli7PyHq+9ybDpTqO5d4UnbvuNbAnEfTUPyDzpqV/mqQFvYAxdjxsDCcdfaeJbga0PvE0iFNejFL0Sxa0YRZ8nqV73SUe9z0gQ3RLPB+PY02oUeVVnIKSw9W6cZ+8n2RZMs3M4zXMPPZ9ix3C97hpdY3swzfbgGi+MeD7FzpE0O4ZTOHMvyStPMlA1E2IumedkZIPTfzYZlhROJ7YYlrP8sFpgSMry9c8NvlzK8Pn3FCcj65xcSXF8Mc230SxfLGb5LqogLG0xFN2EbtQg3m+vcKGSRqZawlw+hcR+Hmm1zF/qBubza5zJpDCVTGLg4w/MKhl82knjVTyKhdwOxpdWMb+9yaWSgpn1JEuqCrFjFgG6/58abDoHp3NMHOF9xobNSrkMYdds1moWdMPAYbYsmobpHTg1XYdmGDBMA2atBt00oOqaz2oNVtMN7GsmbNuCAEHXdeHJC8epv4dt2372as978hG3zvzL1rN/x/wLGqnaC8Jhrk4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Meta-Portfolio based on Heine's Bond Model: Cumulative Returns",
            "title": "Meta-Portfolio based on Heine's Bond Model: Cumulative Returns",
            "src": "/static/e910fda2f2987aaebe48a3f10860d1e8/c1b63/heine-lazy-meta-cumulative.png",
            "srcSet": ["/static/e910fda2f2987aaebe48a3f10860d1e8/5a46d/heine-lazy-meta-cumulative.png 300w", "/static/e910fda2f2987aaebe48a3f10860d1e8/0a47e/heine-lazy-meta-cumulative.png 600w", "/static/e910fda2f2987aaebe48a3f10860d1e8/c1b63/heine-lazy-meta-cumulative.png 1200w", "/static/e910fda2f2987aaebe48a3f10860d1e8/21b4d/heine-lazy-meta-cumulative.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`We tried the following rules:`}</p>
    <ul>
      <li parentName="ul">{`Create a lazy portfolio with four tranches.`}</li>
      <li parentName="ul">{`Trade Heine's Bond Model independently on IEF, TLT, LQD, and JNK`}</li>
    </ul>
    <p>{`We like the simplicity of this approach, which ensures a stable balance between U.S. Treasuries and corporate bonds.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/92eb06d8cda3f959df627e00de13645f/21b4d/heine-lazy-meta-rolling.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACA0lEQVQoz6VSPU8VQRTdP2Bnof/D3sTehsLKwl9AYSOViY2FDWqMBYbYiIWNiYUfMRZgIqEAJbAg74ECj/cB4fF4u29nZmfmnmNmdlfpPZObM/cjd+7kniTLMrxbbWNp8xfer+3DGQVlSgDCAAGI2gICi3g67+G9p6+5tBZKKSSkcGHtlA8+H/Dhlw4bOA+c5g51j3i8VK2NI5q6mGPln41GSLZ6GW6/auHGkxam33RwUo5x/9Nv3H27z7nlPpb3M3o6Ouq6A7lykPFDOuZoEn+BzqjEYjujykZIPm6f8PLMD169l3LqxS7vvG7x0nTKKzMbvP5sgzfndji7eMTZr4dcWO/y+bcjTr3c5rVHW3y61OP8ygCPF3u4Nd+mUTmSs0Lx57HmTr9k60SzfVxy79gyHUy42S/YGhim/YLrh4ar3THXuwXTrmba09HfHOTYHRb83smRK41EvON/As6T3ku1FGstBaSXapMSt1ixskJVCotSYqzJBw7xsfKcGIH1COD5+TkS51ytBkBbgdT6UKUgNLIOKF28w9iKx8rHe9zx32WTw+EQiTIWEwPmRqIVJZpX2cghwEuUUj39hf/WA4gIi6JAMso1lS5hjIFzFlprhKmN0bDOMfghF8zaEtZaGK0rNk1tk7dB2OEVgY+qRVA9KmFXHHznXIyL/Ku5WNv4odUfcwY/JvDrOa0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Meta-Portfolio based on Heine's Bond Model: Rolling Returns",
            "title": "Meta-Portfolio based on Heine's Bond Model: Rolling Returns",
            "src": "/static/92eb06d8cda3f959df627e00de13645f/c1b63/heine-lazy-meta-rolling.png",
            "srcSet": ["/static/92eb06d8cda3f959df627e00de13645f/5a46d/heine-lazy-meta-rolling.png 300w", "/static/92eb06d8cda3f959df627e00de13645f/0a47e/heine-lazy-meta-rolling.png 600w", "/static/92eb06d8cda3f959df627e00de13645f/c1b63/heine-lazy-meta-rolling.png 1200w", "/static/92eb06d8cda3f959df627e00de13645f/21b4d/heine-lazy-meta-rolling.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The results look pretty pleasing. Benchmarked against the aggregate bond market, our meta-strategy continuously produces an upside while at the same time keeping a similar risk profile. However, there are still some periods of negative returns, and the strategy underperformed from 2000 to 2003. Overall, this variant does not do anything that applying Heine's model to AGG didn't do.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/11f70eb8a51479dbbf54840fa4f56f99/21b4d/buoy-cumulative.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACSUlEQVQoz41Ty0tUURg/aNrTaBdUC1ctW7SN1v0NEmGFk8OMrcTNtDDosVEQ3BRBLUIUItqFWosyKpHUHJ3nNXVSm4fzvPM8995zf7+41/FREPSD7/y+873Od/jOETuFEgrFInRdR7VahVKKSinsiWkpWgokXUGTaZiKyaLBmVgZ10Y2eGlQQ7kmIZSy2AR4CM7Gxr6KRM7E+JzOR2+z8I6lePnBOs8PaGy5E4XoCvFcfxzlmgFhmubu0YC7KBtuR87ewWrG4PBUnmf74xTXVyhuNKU7RHE7zFbvCkTvEjoDUWYLZbeg24lT6M8eyZH3ebb7QhQ3lyjuBNneF2SbP8i2Jh/xObIM4QmiMxBjJl+CoK32C9i0OJso8+FkmleG4xTebxSeJSeJLb0hip4whSd8wJ4wWzwRilthXhhwr0yR0et8F9XZ9TzBq0NrPO6PUHQvU/REeOauxg6/xlM+jUe9MXb44zzpi/N0n+byMW+MJ3wxtHpivBj4sTuUhV9Z3pte4+D0Bu9PbnEimOKUluOL+W0+/rDOJ7PbHP2yyVfLaY7OpPhsLsmhj5t8+jXF8cUMxxZTeLmQ4th8GpW6hNBlna/DSUyEtvBG+4m8VYbBBhLVAgKfvuPzZg5zyRzS9SrmM1mslkqYXt1BMJtDVlaQqJQQ1bOIFPIslCsQtlJ/jeLgAdUsi/8Dae0mlIpFCCkl640GGlLCYWkYkE3dMKRrrzn+Zozj34t3c6REpbYbb5oWhFPZ+RG2bWOPD+vubzHNf/oO2xz8Bgd+7G3+RJezAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "TuringTrader's Buoy Portfolio: Cumulative Returns",
            "title": "TuringTrader's Buoy Portfolio: Cumulative Returns",
            "src": "/static/11f70eb8a51479dbbf54840fa4f56f99/c1b63/buoy-cumulative.png",
            "srcSet": ["/static/11f70eb8a51479dbbf54840fa4f56f99/5a46d/buoy-cumulative.png 300w", "/static/11f70eb8a51479dbbf54840fa4f56f99/0a47e/buoy-cumulative.png 600w", "/static/11f70eb8a51479dbbf54840fa4f56f99/c1b63/buoy-cumulative.png 1200w", "/static/11f70eb8a51479dbbf54840fa4f56f99/21b4d/buoy-cumulative.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`TuringTrader's Buoy`}</h2>
    <p>{`To further drive returns, we created a slightly more complex meta-strategy based on `}<em parentName="p">{`Heine's Bond Trading Model`}</em>{`:`}</p>
    <ul>
      <li parentName="ul">{`consider JNK, LQD, TLT, TLH, IEF, IEI, and SHY a universe with a known performance ranking
use Heine's Bond Trading Model to determine investability for each asset in the universe
in addition, validate high-yield bonds with Market Vane
allocate equal capital to the top two investable assets
if no investable assets exist, invest in the fallback asset
choose TIPS and T-bills as the fallback asset, based on their relative momentum`}</li>
    </ul>
    <p>{`We call this new strategy `}<a parentName="p" {...{
        "href": "/portfolios/tt-buoy/"
      }}>{`TuringTrader's Buoy`}</a>{`. The equity chart demonstrates how it has massively outperformed the aggregate bond market while maintaining a similar risk profile.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/cce234fd64a7a0278cb6bf57ebf04ba2/21b4d/buoy-rolling.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB5klEQVQoz6VSPYsTURQd0MLGXyB2+gNsLOwtxUrB/7CFdrJsI4KorYWilT9ARNdGRMXGQlyzJH5k1nUXNptskjWZJPMm7/OeI/NmR6YUvHA49z3OvdyvZDydYTLNkM1mUErB+0DrPEiwtaf5rqvYNAAMIdD7gJqdD3TOwxiDRIJvaiPX79uvDrH2fFy+8XIzx0SF6ONIEwS1PjpZNkOyNBbpYYG7b/ei9nVHMR1aehFeuLfNU6ttvk9znllL+fTjjFUisj+tChksDC4/+oUPaUGrMiRL7Xjp4Q5P3mjzWWfEs7c6PHeny/P3uzxx/QuPr2zy9Oo3Hltp8eqTXa5/nfDN1pQXH/zkeifjzRf7SK59xpXHu7S6QOK95/bY8UffsdXP2TlQbO2rOL90pGO13weG3QPLznDBjV7Ond+a7UHBT70Zu6MCW0PHjd4c2lomznv+p6HegNYaMWE523LAIohcQ4C4qZKNEypTITfCwgqNF2onsB6lcT6fVy1X+StrbA1BAOMFhRUsraBpTd1RPCeTCRLnHJr1l9W6gIilFVoPCv6hb4BaGyR5YZjlGnOlkeVLLAoDtTRYKA1tLJw18WBLWGsjar/5V86v5CTeVQgQkfLyAZEIkcov/8ouak2ta/LfWAB/AICbPWlnW9/VAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "TuringTrader's Buoy Portfolio: Rolling Returns",
            "title": "TuringTrader's Buoy Portfolio: Rolling Returns",
            "src": "/static/cce234fd64a7a0278cb6bf57ebf04ba2/c1b63/buoy-rolling.png",
            "srcSet": ["/static/cce234fd64a7a0278cb6bf57ebf04ba2/5a46d/buoy-rolling.png 300w", "/static/cce234fd64a7a0278cb6bf57ebf04ba2/0a47e/buoy-rolling.png 600w", "/static/cce234fd64a7a0278cb6bf57ebf04ba2/c1b63/buoy-rolling.png 1200w", "/static/cce234fd64a7a0278cb6bf57ebf04ba2/21b4d/buoy-rolling.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The rolling returns illustrate how the strategy has created a very smooth upside since 2004. At the same time, the Buoy strategy has nicely avoided periods of negative returns. Most importantly, the new strategy has worked well through periods of rising rates, particularly from 2016 to 2018 and from 2021 onward.`}</p>
    <h2>{`Conclusions`}</h2>
    <p>{`Our research brings us to the following conclusions:`}</p>
    <p><em parentName="p">{`U.S. Treasuries continue to be a valuable hedge for the stock market`}</em>{` as they continue to negatively correlate to the U.S stock market, despite their historically low yields. This behavior anchors in the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Flight-to-quality"
      }}>{`Flight-to-quality effect`}</a>{`, which leads to investors piling into safer asset classes in times of crisis. And while other asset classes, e.g., `}<a parentName="p" {...{
        "href": "https://www.cboe.com/tradable_products/vix/"
      }}>{`VIX futures`}</a>{` like `}<a parentName="p" {...{
        "href": "https://www.ipathetn.com/US/16/en/details.app?instrumentId=341408"
      }}>{`Barclays' VXX`}</a>{`, have higher hedging capabilities, these products typically have a `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Carry_(investment)"
      }}>{`negative carry`}</a>{`, making market timing very critical. In contrast, U.S. Treasuries have a positive carry most of the time, which leads to more docile behavior.`}</p>
    <p><em parentName="p">{`Long-term Treasuries have better hedging properties but also much more volatility`}</em>{`. A tactical bond strategy should gravitate toward longer maturities but reduce bond duration in times of rising yields to avoid drawdowns. Traditional methods based on relative momentum are not suitable for the task.`}</p>
    <p><em parentName="p">{`A high-powered bond strategy should include asset classes beyond U.S. Treasuries`}</em>{` as a method to maintain positive returns across a broad range of economic scenarios. Asset classes to consider are `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Corporate_bond"
      }}>{`corporate bonds`}</a>{`, `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/High-yield_debt"
      }}>{`junk bonds`}</a>{`, or `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Convertible_bond"
      }}>{`convertible bonds`}</a>{`.`}</p>
    <p><a parentName="p" {...{
        "href": "/portfolios/tt-buoy/"
      }}>{`TuringTrader's Buoy`}</a>{` aligns well with these findings. It combines investments in U.S. Treasuries of various maturities with corporate bonds of investment-grade and junk quality. As a result, we are confident that our Buoy portfolio will help stabilize balanced portfolios while at the same time significantly improving returns over the aggregate bond market.`}</p>
    <br />
    <ButtonPrimary text="Check Out Buoy" to="/portfolios/tt-buoy/" mdxType="ButtonPrimary" />
    <br />
    <br />
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      